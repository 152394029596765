import { createAsyncThunk } from "@reduxjs/toolkit";
import { useHttp } from "../hooks/useHttp";

export const postContactData = createAsyncThunk(
  "contactData/post",
  async (data) => {
    const { request } = useHttp();
    return await request({
      url: "/blog/order/create/",
      method: "POST",
      data,
    });
  }
);
