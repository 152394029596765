import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();

  const { sets } = useSelector((state) => state.sets);

  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    DETAILED: "Details",
    MOCKUPS: "Mockups",
    YOUTUBE: "Youtube Videos",
    VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "Set 1",
      type: types.DOCUMENT,
      document: {
        setInfo:
          "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        client: "Ruby Clinton",
        services: "Hijoma, SPA",
        price: "4.340.000",
        date: "July 16, 2019",
        url: {
          name: "www.example.com",
          link: "https://www.example.com",
        },

        sliderImages: [
          "https://doridarmon.uz/uploads/news/9c77d90fb27024a3a8154fdbbc61331c.jpg",
          "https://doridarmon.uz/uploads/news/9c77d90fb27024a3a8154fdbbc61331c.jpg",
        ],
        illnesses: "Shamollash, Tumov, Grip",
      },

      thumbImage:
        "https://doridarmon.uz/uploads/news/9c77d90fb27024a3a8154fdbbc61331c.jpg",

      categories: [filters.DETAILED],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, [isRtl]);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section"}>
        <div className={"container"}>
          {/* Heading */}
          <div className="row mb-5 wow fadeInUp">
            <div className="col-lg-9 col-xl-8 mx-auto text-center">
              <h2 className="fw-600 mb-3">Сеты</h2>
              <hr className="heading-separator-line border-light border-2 opacity-10 mx-auto" />
              {/* <p className="text-4 text-muted">
                I help you build brand for your business at an affordable price.
                Thousands of clients have procured exceptional results while
                working with Me.
              </p> */}
            </div>
          </div>

          {/* Heading end*/}

          <div className="portfolio">
            <div className="row portfolio-filter filter-container g-4">
              {sets?.count > 0 &&
                sets?.results?.map((set, index) => (
                  <div
                    className={"col-sm-6 col-lg-4 filter-item wow fadeInLeft"}
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={set.image}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails({
                              ...sets?.results[index],
                              type: "document",
                            });
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 "></button>
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">{set?.name}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
