import { createSlice } from "@reduxjs/toolkit";
import { getAllSets } from "../../api/sets";

const initialState = {
  sets: {},
  setsLoading: true,
};

const setsSlice = createSlice({
  name: "sets",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getAllSets.pending, (state) => {
        state.setsLoading = true;
      })
      .addCase(getAllSets.fulfilled, (state, { payload }) => {
        state.sets = payload;
        state.setsLoading = false;
      })
      .addCase(getAllSets.rejected, (state) => {
        state.setsLoading = false;
      }),
});

export default setsSlice.reducer;
export const {} = setsSlice.actions;
