import { createSlice } from "@reduxjs/toolkit";
import { getAllServices } from "../../api/services";

const initialState = {
  services: [],
  servicesLoading: false,
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getAllServices.pending, (state) => {
        state.servicesLoading = true;
      })
      .addCase(getAllServices.fulfilled, (state, { payload }) => {
        state.services = payload;
        state.servicesLoading = false;
      })
      .addCase(getAllServices.rejected, (state) => {
        state.servicesLoading = false;
      }),
});

export default servicesSlice.reducer;
export const {} = servicesSlice.actions;
