import { createSlice } from "@reduxjs/toolkit";
import { postContactData } from "../../api/contact";

const initialState = {
  contactData: {},
  contactLoading: false,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postContactData.pending, (state) => {
        state.contactLoading = true;
      })
      .addCase(postContactData.fulfilled, (state, { payload }) => {
        state.contactData = payload;
        state.contactLoading = false;
      })
      .addCase(postContactData.rejected, (state) => {
        state.contactLoading = false;
      });
  },
});

export default contactSlice.reducer;
export const {} = contactSlice.actions;
