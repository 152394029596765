export const IntroSecDetails = {
  heading1: "Добро пожаловать в ",
  typeWritterHeadings: [
    "Хиджамаузб",
    // "I'm a Freelancer",
    // "I'm a Photographer",
  ],
  heading2: "Скидка 20% на массаж и спа услуги.",
  btnText: "Hачать",
};
export const AboutSecDetails = {
  image: "images/about-me.jpg",
  information: {
    title1: "О нас",
    desc1:
      "Лечебно-диагностический центр «zilolamedical»(ООО «Zilola Medical Service»). Комплексная диагностика на современном оборудовании и лечение. В центре работают высококвалифицированные врачи. Уже после первого визита пациенты получают точный диагноз и индивидуальный план терапевтического лечения. Центр оснащен инновационными и передовыми оборудованиями мировых брендов для точной диагностики и дальнейшей разработки адекватного лечения. Отдел кардиологии оснащён оборудованием суточного мониторинга ЭКГ и давления всемирно-известной Европейской компании Labtech. В центре принимает высококвалифицированный врач-гирудотерапевт с многолетним опытом работы. ",
  },
  moreAbout: [
    {
      num: 10,
      title: "Лет Опыта",
    },
    {
      num: 350,
      title: "Счастливые Rлиенты",
    },
    // {
    //   num: 780,
    //   title: "Projects Done",
    // },
  ],
};

export const ServicesDetails = {
  heading: {
    title: "Услуги",
    text: "How I can help take your next project to new heights! Thousands of clients have procured exceptional results while working with Me.",
  },
  details: [
    {
      name: "Hijoma",
      desc: ["arterit", "shamollash"],
      icon: "https://i.pinimg.com/736x/62/4b/b8/624bb8276a281077272b15b9ef67169a.jpg",
    },
    {
      name: "SPA",
      desc: ["arterit", "shamollash"],
      icon: "https://i.pinimg.com/736x/63/18/d7/6318d7bcf81a61ed253c2fb2c2e21988.jpg",
    },
    {
      name: "Hijoma",
      desc: ["arterit", "shamollash"],
      icon: "https://i.pinimg.com/736x/62/4b/b8/624bb8276a281077272b15b9ef67169a.jpg",
    },
    {
      name: "Hijoma",
      desc: ["arterit", "shamollash"],
      icon: "https://i.pinimg.com/736x/62/4b/b8/624bb8276a281077272b15b9ef67169a.jpg",
    },
    {
      name: "Hijoma",
      desc: ["arterit", "shamollash"],
      icon: "https://i.pinimg.com/736x/62/4b/b8/624bb8276a281077272b15b9ef67169a.jpg",
    },
    {
      name: "Hijoma",
      desc: ["arterit", "shamollash"],
      icon: "https://i.pinimg.com/736x/62/4b/b8/624bb8276a281077272b15b9ef67169a.jpg",
    },
  ],
};

export const MyWorking_Skills = {
  heading: {
    title: "Why I'm Different?",
    text: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
  },
  video: {
    vdo: "http://www.youtube.com/watch?v=7e90gBu4pas",
  },
  working: [
    {
      name: "Unique Design",
      icon: "fas fa-fingerprint",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Fully Customisable",
      icon: "fas fa-cog",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Different Layout",
      icon: "fas fa-layer-group",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Responsive Layout",
      icon: "fas fa-mobile-alt",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Boxed & Wide Layouts",
      icon: "fas fa-expand-alt",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
    {
      name: "Extensive Documentation",
      icon: "far fa-life-ring",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge.",
    },
  ],
  skills: [
    {
      skill: "Branding & Design",
      percent: 95,
    },
    {
      skill: "Web Development",
      percent: 65,
    },
    {
      skill: "Business Analysis",
      percent: 80,
    },
    {
      skill: "Digital Marketing",
      percent: 75,
    },
  ],
};

export const CallToActionDetails = {
  heading: "Start a project with Me today!",
  subHeading: "I am Available for Freelancer.",
  btnText: "Hire Me",
};

export const WorkProcessDetails = {
  heading: {
    title: "Work Process",
    text: "Simple work process of build your design. Delivering work within time and budget which meets client’s requirements is our moto.",
  },
  WDetails: [
    {
      sno: 1,
      name: "Planning & Consulting",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 2,
      name: "Content",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 3,
      name: "Conception",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 4,
      name: "Design & Development",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 5,
      name: "Final discussion",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
    {
      sno: 6,
      name: "Delivery & Launch",
      desc: "We're smart, we're hard working, we're easy to talk to, and we love a challenge lisque persius mea essent possim iriure.",
    },
  ],
};

export const OurClientsDetails = {
  heading: {
    title: "Our Clients",
    text: "See what my happy clients say about me. I'm proud to have my clients.",
  },
  clients: [
    {
      name: "Jay Shah",
      img: "images/testimonial/client-sm-1.jpg",
      exp: "Founder at Icomatic Pvt Ltd",
      summary:
        " Easy to use, reasonably priced simply dummy text of theprinting and typesetting industry. Quidam lisque persiusinteresset his et, in quot quidam possim iriure. simply dummy text of the printing and typesetting industry.",
    },
    {
      name: "Patric Cary",
      img: "images/testimonial/client-sm-2.jpg",
      exp: "Freelancer from USA",
      summary:
        " “I am happy Working with printing and typesetting industry.Quidam lisque persius interesset his et, in quot quidampersequeris essent possim iriure. simply dummy text of theprinting and typesetting industry.”",
    },
    {
      name: "Dennis Jacques",
      img: "images/testimonial/client-sm-3.jpg",
      exp: "iDeal Inc",
      summary:
        " “Only trying it out since a few days. But up to now excellent.Seems to work flawlessly. priced simply dummy text of theprinting and typesetting industry.”",
    },
    {
      name: "Chris Tom",
      img: "images/testimonial/client-sm-4.jpg",
      exp: "Company CEO from UK",
      summary:
        " “I have used them twice now. Good rates, very efficientservice and priced simply dummy text of the printing andtypesetting industry quidam interesset his et. simply dummy text of the printing and typesetting industry. Excellent.”",
    },
  ],
  posters: [
    {
      id: 1,
      img: "images/clients/client-logo2.png",
    },
    {
      id: 2,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 3,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 4,
      img: "images/clients/client-logo2.png",
    },
    {
      id: 5,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 6,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 7,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 8,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 9,
      img: "images/clients/client-logo2.png",
    },
    {
      id: 10,
      img: "images/clients/client-logo3.png",
    },
    {
      id: 11,
      img: "images/clients/client-logo1.png",
    },
    {
      id: 12,
      img: "images/clients/client-logo2.png",
    },
  ],
};

export const OurTeamDetails = {
  heading: {
    title: "Наша команда",
    text: "See what my happy clients say about me. I'm proud to have my clients.",
  },
  staff: [
    {
      name: "Jay Shah",
      img: "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQkIX9qHtGLePrH2wdKSagsifel6LrTjYGU0MqZ50xjtaBzZp52",
      exp: "Founder at Icomatic Pvt Ltd",
      videoLink:
        "https://ru.pinterest.com/f715f688-5aef-44bb-9a13-4b18f149e1d7",
      socialPages: [
        {
          name: "instagram",
          link: "https://instagram.com",
        },
        {
          name: "telegram",
          link: "https://telegram.ru",
        },
        {
          name: "youtube",
          link: "https://youtube.com",
        },
      ],
    },
    {
      name: "Jay Shah",
      img: "images/testimonial/client-sm-1.jpg",
      exp: "Founder at Icomatic Pvt Ltd",
      videoLink:
        "https://ru.pinterest.com/f715f688-5aef-44bb-9a13-4b18f149e1d7",
      socialPages: [
        {
          name: "instagram",
          link: "https://instagram.com",
        },
        {
          name: "telegram",
          link: "https://telegram.ru",
        },
        {
          name: "youtube",
          link: "https://youtube.com",
        },
      ],
    },
    {
      name: "Jay Shah",
      img: "images/testimonial/client-sm-1.jpg",
      exp: "Founder at Icomatic Pvt Ltd",
      videoLink:
        "https://ru.pinterest.com/f715f688-5aef-44bb-9a13-4b18f149e1d7",
      socialPages: [
        {
          name: "instagram",
          link: "https://instagram.com",
        },
        {
          name: "telegram",
          link: "https://telegram.ru",
        },
        {
          name: "youtube",
          link: "https://youtube.com",
        },
      ],
    },
    {
      name: "Jay Shah",
      img: "images/testimonial/client-sm-1.jpg",
      exp: "Founder at Icomatic Pvt Ltd",
      videoLink:
        "https://ru.pinterest.com/f715f688-5aef-44bb-9a13-4b18f149e1d7",
      socialPages: [
        {
          name: "instagram",
          link: "https://instagram.com",
        },
        {
          name: "telegram",
          link: "https://telegram.ru",
        },
        {
          name: "youtube",
          link: "https://youtube.com",
        },
      ],
    },
  ],
};

export const ContactDetails = [
  {
    icon: "fas fa-map-marker-alt",
    title: "Посетите нас",
    p1: "ул. Таллимаржон 43, Мирабадский район, Ташкент",
    p2: "Ориентиры: Международная Клиника, «Американская школа», Мечеть «Ал-Бадр»",
  },
  {
    icon: "fas fa-phone-alt",
    title: "Позвоните нам сейчас",
    p1: "Телефон: +(998) 99 484 09 05",
    p2: "Факс: +(998) 99 484 09 05",
  },
  {
    icon: "fas fa-envelope",
    title: "Запросы",
    p1: "hijomausb@gmail.com",
    p2: "C понедельника по пятницу(10:00 – 20:00)",
  },
];

export const socialIcons = [
  {
    title: "Instagram",
    icon: "fab fa-instagram",
    href: "https://instagram.com/hijomauzb/",
    className: "social-icons-instagram",
  },
  {
    title: "Telegram",
    icon: "fab fa-telegram",
    href: "https://t.me/hijomausb/",
    className: "social-icons-instagram",
  },
  // {
  //   title: "Facebook",
  //   icon: "fab fa-facebook-f",
  //   href: "http://www.facebook.com/harnishdesign/",
  //   className: "social-icons-facebook",
  // },
  {
    title: "Youtube",
    icon: "fab fa-youtube",
    href: "https://youtube.com/@hijomauzbofficial/",
    className: "social-icons-instagram",
  },
  // {
  //   title: "Linkedin",
  //   icon: "fab fa-linkedin-in",
  //   href: "http://www.linkedin.com/",
  //   className: "social-icons-linkedin",
  // },
  // {
  //   title: "Dribbble",
  //   icon: "fab fa-dribbble",
  //   href: "http://www.dribbble.com/harnishdesign/",
  //   className: "social-icons-dribbble",
  // },
  // {
  //   title: "GitHub",
  //   icon: "fab fa-github",
  //   href: "http://www.github.com/",
  //   className: "social-icons-github",
  // },
];
