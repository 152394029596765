import React, { useEffect } from "react";
import { ServicesDetails } from "../config/dataConfig";
import { useDispatch, useSelector } from "react-redux";
import { getAllServices } from "../api/services";

const Services = () => {
  const dispatch = useDispatch();

  const { services } = useSelector((state) => state.services);

  // services details
  return (
    <section id="services" className="section bg-dark">
      <div className="container">
        {/* Heading */}
        <div className="row mb-4 wow fadeInUp">
          <div className="col-lg-9 col-xl-8 mx-auto text-center">
            <h2 className="fw-600 mb-3">{ServicesDetails.heading.title}</h2>
            <hr className="heading-separator-line border-light border-2 opacity-10 mx-auto" />
            {/* <p className="text-4 text-muted">{ServicesDetails.heading.text}</p> */}
          </div>
        </div>
        {/* Heading End */}
        <div className="row g-4">
          {services?.map(({ title, image, description, price, id }, index) => (
            <div key={id} className="col-sm-6 col-lg-4 wow fadeInLeft">
              <div className="featured-box bg-body rounded shadow-sm overflow-hidden">
                <div
                  className="featured-box-icon text-primary"
                  style={{ height: "200px", width: "100%" }}
                >
                  {" "}
                  <img
                    src={image}
                    className="img-fluid"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </div>
                <div className="px-3 py-1">
                  <div className="d-flex mb-2 align-items-center">
                    <h3 className="" style={{ margin: "0", lineHeight: 1.2 }}>
                      {title}
                    </h3>
                    <a className="mx-2 text-secondary">видео</a>
                  </div>
                  <p className="text-muted" style={{ fontSize: "15px" }}>
                    Болезни: {description}
                  </p>
                  <h3 className="col text-light" style={{ fontSize: "24px" }}>
                    {price || "1.200.000"} сум
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
