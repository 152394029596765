import React from "react";
import { OurTeamDetails } from "../config/dataConfig";
import Slider from "react-slick";
import { Tooltip } from "./Tooltip";

const Team = () => {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    margin: 10,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="team" className="section bg-dark">
      <div className="container">
        {/* Heading */}
        <div className="row mb-5 wow fadeInUp">
          <div className="col-lg-9 col-xl-8 mx-auto text-center">
            <h2 className="fw-600 mb-3">{OurTeamDetails.heading.title}</h2>
            <hr className="heading-separator-line border-light border-2 opacity-10 mx-auto" />
            {/* <p className="text-4 text-muted">{OurTeamDetails.heading.text}</p> */}
          </div>
        </div>
        {/* Heading End */}
        <div className="row wow fadeInLeft">
          <div className="col-lg-9 mx-auto">
            <Slider {...settings}>
              {OurTeamDetails.staff.map(({ name, img, exp, socialPages }) => (
                <div key={name} className="item text-center slider-item h-100">
                  {/* <span className="text-9 text-muted opacity-3">
                    <i className="fa fa-quote-left" />
                  </span> */}
                  {/* <p className="text-4">{summary}</p> */}
                  <div style={{ height: "300px" }}>
                    <img
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={img}
                      alt=""
                    />
                  </div>
                  <strong className="d-block fw-500 mt-2">{name}</strong>
                  <span className="text-muted text-2">{exp}</span>
                  <div className="d-flex justify-content-center mt-2">
                    {socialPages.map((page, index) => (
                      <div key={index} style={{ marginRight: "12px" }}>
                        <a
                          href={page?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-light fs-5"
                        >
                          <i className={`fab fa-${page?.name}`} />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
