import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { ContactDetails } from "../config/dataConfig";
import { useDispatch, useSelector } from "react-redux";
import { postContactData } from "../api/contact";

const Contact = () => {
  const form = useRef();

  const dispatch = useDispatch();

  const { contactData, contactLoading } = useSelector((state) => state.contact);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    dispatch(postContactData(formData));
  };

  useEffect(() => {
    if (contactData?.id) {
      toast.success("Ваш запрос был доставлен!", {
        theme: "colored",
      });
      form.current.reset();
    }
  }, [contactData]);

  return (
    <section id="contact" className="section" data-bs-theme="auto">
      <div className="container">
        {/* Heading */}
        <div className="row mb-5 wow fadeInUp">
          <div className="col-lg-9 col-xl-8 mx-auto text-center">
            <h2 className="fw-600 mb-3">Связаться с нами</h2>
            <hr className="heading-separator-line border-light border-2 opacity-10 mx-auto" />
            {/* <p className="text-4 text-muted">
              Send me a note, and let’s get started on your project today!
            </p> */}
          </div>
        </div>
        {/* Heading End */}
        {/* Contact Form */}
        <div className="row wow fadeInLeft">
          <div className="col-lg-10 col-xl-9 mx-auto">
            <form id="contact-form" ref={form} onSubmit={handleSubmit}>
              <div className="row g-4">
                <div className="col-md-6">
                  <input
                    name="full_name"
                    type="text"
                    className="form-control border-2"
                    required
                    placeholder="Ваше имя"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    name="phone_number"
                    type="text"
                    className="form-control border-2"
                    required
                    placeholder="Ваш номер телефона"
                  />
                </div>
                <div className="col-md-12">
                  <textarea
                    name="text"
                    className="form-control border-2"
                    rows={5}
                    required
                    placeholder="Расскажите нам больше о ваших потребностях........"
                    defaultValue={""}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <button
                    id="submit-btn"
                    className="btn btn-light rounded-pill d-inline-flex"
                    type="submit"
                  >
                    {contactLoading ? (
                      <>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm align-self-center me-2"
                        ></span>
                        Отправка......
                      </>
                    ) : (
                      <>Отправить сообщение</>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Contact Form End */}
        <div className="brands-grid separator-border h-100 mt-5">
          <div className="row">
            {ContactDetails.map(({ icon, p1, p2, title }, index) => (
              <div className="col-md-6 col-lg-4 wow fadeInLeft" key={index}>
                <div className="featured-box text-center my-3 my-md-0">
                  <div className="featured-box-icon text-secondary text-opacity-50">
                    {" "}
                    <i className={icon} />
                  </div>
                  <h3 className="text-uppercase">{title}</h3>
                  <p className="text-muted mb-0">
                    {p1}
                    <br />
                    {p2}{" "}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
