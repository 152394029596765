import React from "react";

import { AboutSecDetails, OurClientsDetails } from "../config/dataConfig";
import { Link } from "react-scroll";
import { scrollDuration } from "../config/commonConfig";
import Slider from "react-slick";

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <i className="fa fa-chevron-left"></i>
  </button>
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <i className="fa fa-chevron-right"></i>
  </button>
);
var settings = {
  dots: false,
  arrows: true,
  prevArrow: <SlickArrowLeft />,
  nextArrow: <SlickArrowRight />,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
};

const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        <div
          className="text-center text-lg-start ps-lg-4 wow fadeInUp"
          data-wow-delay="0.2s"
        >
          <h2 className="fw-600 text-center">
            {AboutSecDetails.information.title1}
          </h2>
          <hr className="heading-separator-line border-light border-2 opacity-10 mx-auto mb-4" />
          {/* <h3 className="text-6 mb-4 text-center">
            Hello!
            <span className="text-primary fw-600">
              {" "}
              I'm {AboutSecDetails.information.name}.
            </span>
          </h3> */}
          <p className="text-center w-75 mx-auto">
            {AboutSecDetails.information.desc1}
          </p>
          {/* <p className="text-center">{AboutSecDetails.information.desc2}</p> */}
          <div className="brands-grid separator-border my-sm-4 w-75 mx-auto">
            <div className="row justify-content-center">
              {AboutSecDetails.moreAbout.map(({ num, title }, index) => (
                <div className="col-sm-6 py-4 py-sm-2" key={index}>
                  <div className="featured-box text-center">
                    <h4 className="text-8 text-light-emphasis mb-0">
                      <span className="counter" data-from={0} data-to={10}>
                        {num}
                      </span>
                      +
                    </h4>
                    <p className="mb-0">{title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <a
              className="btn btn-secondary rounded-pill mt-3"
              data-bs-toggle="modal"
              data-bs-target="#about-us-details"
              href="#about-us-details"
            >
              About more
            </a> */}
          {/* <Link
            to="services"
            spy
            smooth="easeInOutQuint"
            duration={scrollDuration}
            style={{ cursor: "pointer" }}
            className="smooth-scroll mt-10"
          >
            Bizning Xizmatlar
            <span className="text-1 ms-2">
              <i className="fas fa-chevron-right" />
            </span>
          </Link> */}
        </div>
        <div className="wow fadeInLeft">
          <img
            className="img-fluid shadow-md rounded d-inline-block"
            src={AboutSecDetails?.image}
            style={{ width: "100%", height: "700px", objectFit: "cover" }}
            title="I'm Kenil Patel"
            alt=""
          />
        </div>
        {/* <div className="row">
          <div className="col-lg-7 text-center mb-5 mb-lg-0 wow fadeInUp">
            <img
              className="img-fluid shadow-md rounded d-inline-block"
              src="images/about-me.jpg"
              title="I'm Kenil Patel"
              alt=""
            />
          </div>
          <div
            className="col-lg-5 text-center text-lg-start ps-lg-4 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <h2 className="fw-600 mb-4">
              {AboutSecDetails.information.title1}
            </h2>
            <h3 className="text-6 mb-4">
              Hello!
              <span className="text-primary fw-600">
                {" "}
                I'm {AboutSecDetails.information.name}.
              </span>
            </h3>
            <p>{AboutSecDetails.information.desc1}</p>
            <p>{AboutSecDetails.information.desc2}</p>
            <div className="brands-grid separator-border my-sm-4">
              <div className="row">
                {AboutSecDetails.moreAbout.map(({ num, title }, index) => (
                  <div className="col-sm-4 py-4 py-sm-2" key={index}>
                    <div className="featured-box text-center">
                      <h4 className="text-8 text-light-emphasis mb-0">
                        <span className="counter" data-from={0} data-to={10}>
                          {num}
                        </span>
                        +
                      </h4>
                      <p className="mb-0">{title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <a
              className="btn btn-secondary rounded-pill mt-3"
              data-bs-toggle="modal"
              data-bs-target="#about-us-details"
              href="#about-us-details"
            >
              About more
            </a>
            <Link
              to="services"
              spy
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer" }}
              className="smooth-scroll mt-10"
            >
              Bizning Xizmatlar
              <span className="text-1 ms-2">
                <i className="fas fa-chevron-right" />
              </span>
            </Link>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AboutUs;
