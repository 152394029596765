import { configureStore } from "@reduxjs/toolkit";

import contact from "./slices/contact.slice";
import sets from "./slices/sets.slice";
import services from "./slices/services.slice";

export const store = configureStore({
  reducer: {
    contact,
    sets,
    services,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
});
